import { UseLocalStorage } from "../../../../hooks/useLocalStorage/useLocalStorage";

const forceShow = (position: string) => {
  const browser = window as Window;
  const params = new URLSearchParams(browser.location.search);

  let forceShow;
  if (["takeoverDesktop", "takeoverMobile"].includes(position)) {
    forceShow = params.get("takeover");
  } else {
    forceShow = params.get("fullscreen");
  }

  return forceShow === "1";
};

const frequencyController = (
  frequency: number,
  position: string,
  timedOut: boolean = false,
): boolean => {
  if (timedOut) {
    return false;
  }

  const localStorageItemName = `${position}"_fetch_frequency"`;
  const localStorageItem = UseLocalStorage().get(localStorageItemName);
  const lastSeen = Number(localStorageItem || 0);
  const now = new Date().getTime();
  const timeSinceLastSeen = now - lastSeen;
  const frequencyLimit = frequency * 1000 * 60 * 60;

  if (timeSinceLastSeen < frequencyLimit && !forceShow(position)) {
    return false;
  }

  UseLocalStorage().set(localStorageItemName, `${now}`);

  return true;
};

export { frequencyController };
