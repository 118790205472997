const updateClientMetaTag = (
  name: string,
  content?: string,
  attr: "name" | "property" = "name",
) => {
  if (!content) return;
  let element = document.querySelector(`meta[${attr}="${name}"]`);
  if (!element) {
    element = document.createElement("meta");
    element.setAttribute(attr, name);
    document.head.appendChild(element);
  }
  element.setAttribute("content", content);
};

export { updateClientMetaTag };
